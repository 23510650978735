import React, { ReactElement, useState } from 'react'
import { graphql } from 'gatsby'
import './EstatesList.scss'
import Layout from '../../layouts/Layout/Layout'
import NeighborhoodLayout from '../../layouts/NeighborhoodLayout/NeighborhoodLayout'
import Seo from '../../components/Seo/Seo'
import loadable from '@loadable/component'
import { ImageDataLike } from 'gatsby-plugin-image'

const MiniSliderCard = loadable(() =>
  import('../../components/MiniSliderCard/MiniSliderCard'),
)

const NoResult = loadable(() => import('../../components/NoResult/NoResult'))

export type EstatesTypes = {
  id: string
  slug: string
  featuredImage: string
  optimizedFeaturedImg: ImageDataLike
  title: string
  url: string
}

type EstatesPropTypes = {
  data: {
    allEstates: {
      nodes: EstatesTypes[]
    }
  }
  // eslint-disable-next-line
  pageContext: {
    activeTab: string
  }
}
export const Tabs = [
  { label: 'Neighborhood', url: 'neighborhoods' },
  { label: 'Estates', url: 'estates' },
]

const EstatesList = ({
  data: {
    allEstates: { nodes: allEstates },
  },
}: EstatesPropTypes): ReactElement => {
  const [featuredImage] = useState<string>(
    allEstates.find((estate) => estate.featuredImage)?.featuredImage || '',
  )

  return (
    <Layout>
      <Seo
        slug="estates"
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/estates/`,
          description:
            'Donec facilisis tortor ut augue lacinia, at viverra est semper. Sed sapien metus,scelerisque nec pharetra id, tempor a tortor. Pellentesq.',
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, property list',
        }}
        title="Estate"
      />
      <div className="estates-list">
        <NeighborhoodLayout
          navigationTabs={Tabs}
          background={featuredImage}
          title="Estates"
          body="As a residential brand of Ayala Land, the Philippines' leading developer of sustainable estates that support local economic growth, Avida offers properties located in townships with diverse residential and commercial offerings from which thriving communities emerge."
        />
        <div className="estates-list-content">
          <div className="estates-list-content-cards">
            {allEstates.length ? (
              allEstates.map((data) => (
                <MiniSliderCard
                  key={data.id}
                  data={data}
                  url={`/estates/${data.slug}`}
                />
              ))
            ) : (
              <NoResult />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default EstatesList

export const pageQuery = graphql`
  query {
    allEstates {
      nodes {
        ...EstatesListPageArticleFields
      }
    }
  }
`
